import { useSellerStore } from "@/stores/useSellerStore";

export default defineNuxtRouteMiddleware(() => {
  const sellerStore = useSellerStore();

  if (!sellerStore.isSeller) {
    return navigateTo("/");
  }

  return;
});
